import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "./narbar.scss";

const Nav: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
    }, [location]);

    return (
        <>
            <div className="nav-wrapper">
                <Link to="/">
                    <img src="../images/nav-logo.svg" alt="logo" />
                </Link>
                <div className="nav-links">
                    <Link to="/building">
                        {" "}
                        {location.pathname == "/building" && (
                            <motion.div
                                initial={{ y: "-200%" }}
                                animate={{ y: 0 }}
                                className="active-circle"
                                transition={{
                                    duration: 0.3,
                                    type: "spring",                                  
                                }}
                            ></motion.div>
                        )}
                        <h4>BUILDING</h4>
                    </Link>
                    <Link to="/availability">
                        {location.pathname == "/availability" && (
                            <motion.div
                                initial={{ y: "-200%" }}
                                animate={{ y: 0 }}
                                className="active-circle"
                                transition={{ duration: 0.3, type: "spring" }}
                            ></motion.div>
                        )}
                        <h4>SPACE</h4>
                    </Link>
                    <Link to="/sustainability">
                        {location.pathname == "/sustainability" && (
                            <motion.div
                                initial={{ y: "-200%" }}
                                animate={{ y: 0 }}
                                className="active-circle"
                                transition={{ duration: 0.3, type: "spring" }}
                            ></motion.div>
                        )}
                        <h4>SUSTAINABILITY</h4>
                    </Link>
                    <Link to="/location">
                        {location.pathname == "/location" && (
                            <motion.div
                                initial={{ y: "-200%" }}
                                animate={{ y: 0 }}
                                className="active-circle"
                                transition={{ duration: 0.3, type: "spring" }}
                            ></motion.div>
                        )}
                        <h4>location</h4>
                    </Link>
                    <Link to="/contact">
                        {location.pathname == "/contact" && (
                            <motion.div
                                initial={{ y: "-200%" }}
                                animate={{ y: 0 }}
                                className="active-circle"
                                transition={{ duration: 0.3, type: "spring" }}
                            ></motion.div>
                        )}
                        <h4>contact</h4>
                    </Link>
                </div>
            </div>
        </>
    );
};
export default Nav;