import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./stationSwiper.scss";
import Swiper from "swiper";
import { motion } from "framer-motion";

interface Slide {
    station: string;
    connections: string[];
    walking: number;
    cycling: number;
}

interface StationsSwiperProps {
    slides: Slide[];
}

const StationsSwiper: React.FC<StationsSwiperProps> = ({ slides }) => {
    return (
        <>
            <div className="nav-arrow-wrapper">
                <div className="prev">
                    <img src="../images/arrow2.svg" alt="arrow" />
                </div>
                <div className="next">
                    <img src="../images/arrow2.svg" alt="arrow" />
                </div>
            </div>
            <SwiperComponent
                modules={[Autoplay, Navigation]}
                slidesPerView={1}
                id="station"
                navigation={{
                    nextEl: ".next",
                    prevEl: ".prev",
                }}
                direction={"vertical"}                
                loop={true}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide>
                        <div className="station">
                            <p className="reg station">STATION</p>
                            <h4>{slide.station}</h4>
                        </div>
                        <div className="connect">
                            <p className="reg connect">CONNECTIONS</p>
                            <div className="connections">{slide.connections.map((color, index) => (
                                <div className="connection">
                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.71543 11.5C6.71543 13.5108 7.53354 15.3331 8.85511 16.6527C10.1767 17.9723 12.0017 18.7892 14.0155 18.7892C16.0294 18.7892 17.8544 17.9723 19.1759 16.6527C20.4975 15.3331 21.3156 13.5108 21.3156 11.5C21.3156 9.48921 20.4975 7.66692 19.1759 6.34733C17.8544 5.02773 16.0451 4.21084 14.0155 4.21084C11.986 4.21084 10.1767 5.02773 8.85511 6.34733C7.53354 7.66692 6.71543 9.4735 6.71543 11.5ZM14.0155 22.4966C10.9791 22.4966 8.21006 21.2556 6.22771 19.2762C4.22962 17.2968 3.00244 14.5319 3.00244 11.5C3.00244 8.4681 4.22962 5.70324 6.22771 3.72385C8.21006 1.72875 10.9791 0.503418 14.0155 0.503418C17.052 0.503418 19.821 1.74446 21.8034 3.72385C23.8014 5.70324 25.0286 8.4681 25.0286 11.5C25.0286 14.5319 23.7857 17.2968 21.8034 19.2762C19.821 21.2556 17.052 22.4966 14.0155 22.4966Z" fill={`${color}`}/>
                                <path d="M27.5775 9.26929H0.422363V13.7936H27.5775V9.26929Z" fill={`${color}`}/>
                                </svg>
                                </div>
                                
                            ))}</div>
                        </div>
                        <div className="dist-wrapper">
                            <div className="dist">
                                <p className="reg">WALKING</p>
                                <div className="anim">
                                    <h3>{slide.walking}</h3>

                                    <motion.h5
                                        initial={{ backgroundSize: "100% 0%" }}
                                        whileInView={{
                                            backgroundSize: "100% 100%",
                                        }}
                                        transition={{duration: 0.5, delay: 0.3, transition: "linear"}}
                                    >
                                        min
                                    </motion.h5>
                                </div>
                                <div className="border-wrapper">
                                    <motion.div
                                        initial={{}}
                                        whileInView={{ height: "20px" }}
                                        transition={{duration: 0.1, delay: 0.2, transition: "linear"}}
                                        className="border"
                                        
                                    ></motion.div>
                                </div>
                            </div>
                            <div className="dist">
                            <p className="reg">CYCLING</p>
                                <div className="anim">
                                    <h3>{slide.cycling}</h3>

                                    <motion.h5
                                        initial={{ backgroundSize: "100% 0%" }}
                                        whileInView={{
                                            backgroundSize: "100% 100%",
                                        }}
                                        transition={{duration: 0.5, delay: 0.3, transition: "linear"}}
                                    >
                                        min
                                    </motion.h5>
                                </div>
                                <div className="border-wrapper">
                                    <motion.div
                                        initial={{}}
                                        whileInView={{ height: "20px" }}
                                        transition={{duration: 0.1, delay: 0.2, transition: "linear"}}
                                        className="border"
                                        
                                    ></motion.div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </SwiperComponent>
        </>
    );
};
export default StationsSwiper;
