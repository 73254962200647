import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./mobilenav.scss";

const MobileNav: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const toggleNav = () => setIsNavOpen(!isNavOpen);

  return (
    <div className={`mobile-nav-wrapper ${isNavOpen ? "active" : ""}`}>
      <div className="mobnav-header">
        <Link to="/" onClick={() => setIsNavOpen(false)}>

          <motion.svg
            width="39"
            height="34"
            viewBox="0 0 39 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          animate={{opacity: 1}} initial={{opacity: 0}} transition={{delay: location.pathname === '/' ? 3.5 : 0}}
          >
            <path
              d="M5.96359 8.34436H0V25.8515H5.96359V8.34436Z"
              fill="#2A2F1D"
            />
            <path
              d="M38.9997 8.34436H33.0361V25.8515H38.9997V8.34436Z"
              fill="#2A2F1D"
            />
            <path
              d="M26.6709 24.0001C30.6425 20.1341 30.6425 13.8661 26.6709 10.0001C22.6993 6.13413 16.26 6.13413 12.2884 10.0001C8.3168 13.8661 8.3168 20.1341 12.2884 24.0001C16.26 27.8661 22.6993 27.8661 26.6709 24.0001Z"
              fill="#2A2F1D"
            />
          </motion.svg>
        </Link>
        <div className="MobNavClose" onClick={toggleNav}>
          <AnimatePresence>
            {isNavOpen ? (
              <motion.svg
                key="navopened"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="navopened"
              >
                <rect width="40" height="40" fill="#2A2F1D" />
                <rect
                  width="38.2992"
                  height="3.82992"
                  transform="matrix(0.712095 0.702084 -0.712095 0.702084 7.72729 5.42175)"
                  fill="#D9CFBD"
                />
                <rect
                  width="38.2992"
                  height="3.82992"
                  transform="matrix(0.712095 -0.702084 0.712095 0.702084 5 31.8893)"
                  fill="#D9CFBD"
                />
              </motion.svg>
            ) : (
              <motion.svg
                key="navclosed"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="navclosed"
              >
                <rect width="40" height="40" fill="#ded5c4" />
                <rect y="11" width="40" height="4" fill="#2A2F1D" />
                <rect y="25" width="40" height="4" fill="#2A2F1D" />
              </motion.svg>
            )}
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence>
        {isNavOpen && (
          <motion.div
            className="nav-links"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="group-links">
              <Link
                to="/"
                className={location.pathname === "/" ? "actice-circle" : ""}
                onClick={() => setIsNavOpen(false)}
              >
                <h4>HOME</h4>
              </Link>
              <Link
                to="/building"
                className={
                  location.pathname === "/building" ? "actice-circle" : ""
                }
                onClick={() => setIsNavOpen(false)}
              >
                <h4>BUILDING</h4>
              </Link>
              <Link
                to="/availability"
                className={
                  location.pathname === "/availability" ? "actice-circle" : ""
                }
                onClick={() => setIsNavOpen(false)}
              >
                <h4>SPACE</h4>
              </Link>
              <Link
                to="/sustainability"
                className={
                  location.pathname === "/sustainability"
                    ? "actice-circle"
                    : ""
                }
                onClick={() => setIsNavOpen(false)}
              >
                <h4>SUSTAINABILITY</h4>
              </Link>
              <Link
                to="/location"
                className={
                  location.pathname === "/location" ? "actice-circle" : ""
                }
                onClick={() => setIsNavOpen(false)}
              >
                <h4>LOCATION</h4>
              </Link>
              <Link
                to="/contact"
                className={
                  location.pathname === "/contact" ? "actice-circle" : ""
                }
                onClick={() => setIsNavOpen(false)}
              >
                <h4>CONTACT</h4>
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MobileNav;
