import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Building from "./pages/building/building";
import "./scss/global.scss";
import Navbar from "./components/navbar/navbar";
import MobileNav from "./components/mobilenav/mobilenav";
import Footer from "./components/footer/footer";
import Availability from "./pages/availability/availability";
import Sustainability from "./pages/sustainability/sustainability";
import Location from "./pages/location/location";
import Contact from "./pages/contact/contact";
import { AnimatePresence } from "framer-motion";
import './scss/global.scss';
import { useEffect } from "react";

const App: React.FC = () => {
  const routes = [
    { path: "/", component: Home },
    { path: "/building", component: Building },
    { path: "/availability", component: Availability },
    { path: "/sustainability", component: Sustainability },
    { path: "/location", component: Location },
    { path: "/contact", component: Contact },
  ];

  useEffect(() => {
    function isSafari() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.includes("safari") && !ua.includes("chrome");
    }

    if (isSafari()) {
        document.body.classList.add("is-safari");            
    }
}, []);

  return (
    <>
      <AnimatePresence mode="wait">
        <BrowserRouter>
          <div className="bayham">
            <Navbar />
            <MobileNav />
            <Routes>
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                );
              })}
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </AnimatePresence>
    </>
  );
};

export default App;
