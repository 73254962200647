export const sustainIcons = [
    {
        src: '../images/sustainability/icons/windows.svg',
        title: 'OPENABLE WINDOWS',
        copy: 'Over 50 throughout the building'
    },
    {
        src: '../images/sustainability/icons/air.svg',
        title: 'AIR SOURCE HEAT PUMPS',
        copy: 'For heating and cooling AHU Ventilation'
    },
    {
        src: '../images/sustainability/icons/BREEAM.svg',
        title: 'BREEAM',
        copy: 'BREEAM Outstanding'
    },
    {
        src: '../images/sustainability/icons/carbon.svg',
        title: 'REDUCED CARBON',
        copy: 'Restoration of existing building'
    },
    {
        src: '../images/sustainability/icons/cycle.svg',
        title: '30 CYCLE SPACES',
        copy: 'To help support an active lifestyle'
    },
    {
        src: '../images/sustainability/icons/EPC.svg',
        title: 'EPC A',
        copy: 'Energy Performance Certificate'
    },
    {
        src: '../images/sustainability/icons/planting.svg',
        title: 'PLANTING ON TERRACE',
        copy: 'Diverse planting to enhance urban biodiversity'
    },
    {
        src: '../images/sustainability/icons/shading.svg',
        title: 'BESPOKE PASSIVE SOLAR SHADING',
        copy: 'Reducing heat gains through passive shading'
    },
    {
        src: '../images/sustainability/icons/solar.svg',
        title: 'SOLAR POWERED',
        copy: 'PV Panels at roof level'
    },
    {
        src: '../images/sustainability/icons/sustainability.svg',
        title: 'SUSTAINABILITY & WELLBEING',
        copy: 'Light and airy co-working space'
    },
];