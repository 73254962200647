import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./horizontalGallery.scss";

const images = [
  { src: "../images/location/gallery/1.jpg", caption: "Regent's Place" },
  { src: "../images/location/gallery/2.jpg", caption: "Coal Drops Yard" },
  { src: "../images/location/gallery/3.jpg", caption: "Camden Market" },
  { src: "../images/location/gallery/4.jpg", caption: "Camden Market" },
  { src: "../images/location/gallery/5.jpg", caption: "Camden Town" },
  { src: "../images/location/gallery/6.jpg", caption: "Regent’s Canal" },
  { src: "../images/location/gallery/7.jpg", caption: "Coal Drops Yard" },
  { src: "../images/location/gallery/8.jpg", caption: "Coal Drops Yard" },
  // { src: "../images/location/gallery/9.jpg", caption: "Caption 9" },
];

const HorizontalGallery: React.FC = () => {
  return (
    <div className="swiper-container hor">
      <Swiper
      loop={true}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Pagination, Navigation]}
        className="gallSwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="slide">
            <div
              className="slide-image"
              style={{ backgroundImage: `url(${image.src})` }}
            />
            <div className="caption">{image.caption}</div>
          </SwiperSlide>
        ))}
        <div className="swiper-controls">
          <div className="swiper-pagination"></div>
          <div className="swiper-nav">
            <div className="swiper-button-prev">
              <img src="../images/arrow.svg" alt="Previous" />
            </div>
            <div className="swiper-button-next">
              <img src="../images/arrow.svg" alt="Next" />
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default HorizontalGallery;