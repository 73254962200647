import { motion } from "framer-motion";
import { getHighlightAnimation } from "../../utils/animations/globalAnims";
import "./contact.scss";
import { useLayoutEffect } from "react";

const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div className="contain-wrapper">
        <div className="contact-one contain">
        <div className="motion-wrapper">
                    <motion.h2
                        initial={{ y: "100%" }}
                        whileInView={{ y: 0 }}
                        transition={{ duration: 0.5, type: "spring" }}
                    >
                        BRING ON BOARD <br/> THE&nbsp;
                        <motion.span {...getHighlightAnimation(0.2, "#CC8B86")}>
                            RIGHT PEOPLE
                        </motion.span>
                    </motion.h2>
                </div>
        </div>
        </div>
    );
    }
export default Contact;