import { motion } from "framer-motion";
import "./sqft.scss";

interface ColorProps {
    color: string;
}
const SQFT: React.FC<ColorProps> = ({ color }) => {
    return (
        <><div className="sqft-hero">
            <motion.div
                initial={{ backgroundSize: "0% 100%" }}
                whileInView={{ backgroundSize: "100% 100%" }}
                transition={{ duration: 0.25, ease: "easeIn", delay: 0.2 }}
                className="sqft-wrapper"
                style={{
                    backgroundRepeat: "no-repeat",          
                    
                }}
            >
                <h1>22k</h1>
            </motion.div>
            <motion.div
                initial={{ backgroundSize: "0% 100%" }}
                whileInView={{ backgroundSize: "100% 100%" }}
                transition={{ duration: 0.25, ease: "easeIn", delay: 0.2 }}
                className="sqft-wrapper"
                style={{
                    backgroundRepeat: "no-repeat",          
                    
                }}
            >
                <h1>SQ</h1>
            </motion.div>
            <motion.div
                initial={{ backgroundSize: "0% 100%" }}
                whileInView={{ backgroundSize: "100% 100%" }}
                transition={{ duration: 0.25, ease: "easeIn", delay: 0.2 }}
                className="sqft-wrapper"
                style={{
                    backgroundRepeat: "no-repeat",          
                    
                }}
            >
                <h1>FT</h1>
            </motion.div>
            </div>
        </>
    );
};

export default SQFT;
