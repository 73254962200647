import { Link } from "react-router-dom";
import "./card.scss";

interface CardProps {
    title: string;
    highlight?: number | number[];  // Allow both single number and array
    description: string;
    accentColor: string;
    arrowColor: string;
    backgroundColor: string;
    copyColor: string;
    link: string;
    number: string;
    isImage?: boolean;
    imageUrl?: string;
}

const Card: React.FC<CardProps> = ({
    title,
    highlight,  // This can be a single number or an array
    description,
    accentColor,
    arrowColor,
    backgroundColor,
    copyColor,
    link,
    number,
    isImage = false,
    imageUrl = '',
}) => {
    const words = title.split(" ");
    
    // Determine if highlight is an array or a single number
    const highlightIndices = Array.isArray(highlight)
        ? highlight
        : highlight
        ? [highlight]
        : [];
    
    return (
        <>
            <div
                className="card-hero"
                style={{ 
                    backgroundColor: backgroundColor,
                    backgroundImage: `${isImage && `url(${imageUrl})`}`,
                }}
            >
                <h3 style={{ color: copyColor }}>
                    {words.map((word, index) =>
                        highlightIndices.includes(index + 1) ? (  // Check if index+1 is in highlightIndices
                            <span
                                key={index}
                                style={{
                                    backgroundColor: accentColor,
                                    padding: "0 5px",
                                }}
                            >
                                {word}
                            </span>
                        ) : (
                            <span key={index}> {word} </span>
                        )
                    )}
                </h3>{" "}
                <div>
                    <div
                        className="border"
                        style={{ backgroundColor: copyColor }}
                    ></div>
                    <Link to={`/${link}`}>
                    <div className="card-bottom">
                        <div className="link-wrapper">
                            <div
                                className="number"
                                style={{
                                    backgroundColor: accentColor,
                                    color: copyColor,                                    
                                }}
                            >
                                <h5> {number}</h5>
                            </div>
                            <h5 style={{ color: copyColor }}>{description}</h5>
                        </div>
                        <div
                            className="arrow"
                            style={{ backgroundColor: copyColor }}
                        >
                            <svg
                                width="24"
                                height="21"
                                viewBox="0 0 24 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.5862 0.103404L10.8965 2.79308L16.8276 8.65515L-5.39611e-07 8.65515L-3.73809e-07 12.4483L16.8276 12.4483L10.8965 18.3793L13.5862 21L24 10.5861L13.5862 0.103404Z"
                                    fill={arrowColor}
                                />
                            </svg>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Card;