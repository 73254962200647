import { motion } from "framer-motion";
import { getHighlightAnimation } from "../../utils/animations/globalAnims";
import "./sustainability.scss";
import IconSwiper from "../../components/gallery/icons/iconSwiper";
import { sustainIcons } from "../../utils/sustain";
import Card from "../../components/cards/card";
import { useLayoutEffect } from "react";

const Sustainability = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="contain-wrapper green-back">
        <div className="sust-one contain">
          <div className="motion-wrapper">
            <motion.h2
              initial={{ y: "100%" }}
              whileInView={{ y: 0 }}
              transition={{ duration: 0.5, type: "spring" }}
            >
              <motion.span {...getHighlightAnimation(0.2, "#39805a")}>
                PROTECT
              </motion.span>
              &nbsp;THE PLANET & YOUR BOTTOM LINE
            </motion.h2>
          </div>
          <div className="copy-wrapper">
            <h5>SUSTAINABILITY</h5>
            <p className="large">
              Optimising the existing building, providing a workspace that is
              both enhanced and more efficient. Full refurbishment, rather than
              a rebuild, reduces carbon emissions, and promotes sustainable
              practices.
            </p>
          </div>
        </div>
      </div>
      <div className="contain-wrapper green-back">
        <div className="sust-two contain">
          <IconSwiper icons={sustainIcons} />
        </div>
      </div>
      <div className="contain-wrapper pink-back">
        <div className="sust-three contain">
          <h5>WELLBEING</h5>
          <div className="icon-wrappers">
            <div className="icon">
              <img src="../images/sustainability/icons/1.svg" alt="icon" />{" "}
              <p className="title">OPENABLE WINDOWS</p>
              <p>Over 50 throughout the building</p>
            </div>
            <div className="icon">
              <img src="../images/sustainability/icons/2.svg" alt="icon" />{" "}
              <p className="title">Terrace Planting</p>
              <p>Diverse planting to enhance urban biodiversity</p>
            </div>
            <div className="icon">
              <img src="../images/sustainability/icons/3.svg" alt="icon" />
              <p className="title">SUSTAINABILITY & Wellbeing</p>
              <p>Light and airy co-working space</p>
            </div>
            <div className="icon">
              <img src="../images/sustainability/icons/4.svg" alt="icon" />
              <p className="title">30 CYCLE SPACES</p>
              <p>To help support an active lifestyle</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contain-wrapper pink-back">
        <div className="sust-four contain">
          <div className="img-wrapper">
            <div style={{}} className="backimage" />
            <p>Terrace planting</p>
          </div>
          <div className="img-wrapper">
            <div style={{}} className="backimage2" />
            <p>Lower Ground Floor Cycle Suite</p>
          </div>
        </div>
      </div>
      <div className="contain-wrapper pink-back">
        <div className="sust-five contain">
          <div className="wrapper">
            <h5>KEEP YOUR TALENT HAPPY</h5>
            <div className="copy-wrapper">
              <div className="line"></div>
              <div className="copy">
                <p>flexible ground floor SPACE</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>Openable windows</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>4.5M reception floor-ceiling height</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>1,232 sq ft Roof terrace</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>4M high reception windows</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>VRV Air conditioning</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>2.9m floor to ceiling height</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>30 bike spaces & 4 showers</p>
              </div>
              <div className="line"></div>
              <div className="copy">
                <p>1 x 8 person lift</p>
              </div>
              <div className="line"></div>
            </div>
          </div>
          <div className="img-wrapper"></div>
        </div>
      </div>
      <div className="contain-wrapper">
        <div className="card-wrapper contain">
          <Card
            title={"PROTECT YOUR WORK-LIFE SYMMETRY"}
            highlight={3}
            description={"location"}
            accentColor={"#CC8B86"}
            arrowColor={"#DFD8CA"}
            backgroundColor={"#DFD8CA"}
            copyColor={"#FFFFFF"}
            link={"location"}
            number={"04"}
            isImage={true}
            imageUrl={"../images/card-image.jpg"}
          />
          <Card
            title={"GET IN TOUCH TO FIND OUT MORE"}
            highlight={[1, 2, 3]} // Multiple highlights
            description={"contact"}
            accentColor={"#2A2F1D"}
            arrowColor={"#2A2F1D"}
            backgroundColor={"#CC8B86"}
            copyColor={"#D9CFBD"}
            link={"contact"}
            number={"05"}
          />
        </div>
      </div>
    </>
  );
};

export default Sustainability;
