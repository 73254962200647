import { useState } from 'react';
import { motion } from 'framer-motion'; // Import the motion component from framer-motion library
import './accordion.scss';

interface AccordionProps {
    color: string;
    title: string;  
    content: string[];
    numbers: number[];
}

const Accordion: React.FC<AccordionProps> = ({ color, title, content, numbers }) => {
    const [isOpen, setIsOpen] = useState<Boolean>(false);

    const animation = {
        hidden: { opacity: 0, height: 0, padding: '0 20px' },
        show: { opacity: 1, height: 30 * content.length, padding: '10px 20px' }
        
    };

    return (
        <>
            <div className="accordion">
                <div className="accordion-header" onClick={() => setIsOpen(!isOpen)} style={{ backgroundColor: color }}>
                    <h6>{title}</h6>
                    <div className='btn'>{isOpen ? '-' : '+'}</div>
                </div>
                
                <motion.div 
                    variants={animation} 
                    initial="hidden" 
                    animate={isOpen ? 'show' : 'hidden'} 
                    transition={{ duration: 0.3 }} 
                    className={`accordion-content ${isOpen && 'active'}`}
                >
                    {content.map((item, index) => {
                        return (
                            <div className='copy-wrapper'>
                            <p className={`small`} key={index}>{item}</p>
                            <p className={`small`} key={index}>{numbers[index]}</p>
                            </div>
                        )
                    })}
                </motion.div>
            </div>
        </>
    );
}

export default Accordion;
