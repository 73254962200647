import { AnimatePresence, motion } from "framer-motion";
import { getHighlightAnimation } from "../../utils/animations/globalAnims";
import SQFT from "../../utils/animations/sqft/sqft";
import "./availability.scss";
import { useLayoutEffect, useState } from "react";
import Card from "../../components/cards/card";
const Availability = () => {
    const [floorplan, setFloorplan] = useState<number>(0);
    const [isSpacePlan, setIsSpacePlan] = useState<boolean>(false);

    const variants = {
        initial: { x: "100vw" },
        animate: { x: 0, transition: { duration: 0.5, delay: 0.5 } },
        exit: { x: "100vw", transition: { duration: 0.5 } },
    };
    const variantsR = {
        initial: { x: "-100vw" },
        animate: { x: 0, transition: { duration: 0.5, delay: 0.5 } },
        exit: { x: "-100vw", transition: { duration: 0.5 } },
    };
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="contain-wrapper">
                <div className="build-one contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            WORK WITHOUT
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                LIMITATIONS
                            </motion.span>
                        </motion.h2>
                    </div>
                    <div className="copy-wrapper">
                        <h5>SPACE</h5>
                        <p>
                            Well-considered spaces create an enviable
                            environment where employees can collaborate, and
                            feel free to be both more productive and creative.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper olive-back">
                <div className="avail-two contain">
                    <SQFT color="#CC8B86" />
                    <div className="table">
                        <div className="row">
                            <div className="cell">
                                <h6>FLOOR</h6>
                            </div>
                            <div className="cell">
                                <h6>SQ FT</h6>
                            </div>
                            <div className="cell">
                                <h6>SQ M</h6>
                            </div>
                        </div>
                        <motion.div
                            initial={{ height: 0 }}
                            whileInView={{ height: "22px" }}
                            style={{ backgroundColor: "#CC8B86" }}
                            transition={{ duration: 0.2, delay: 0.25 }}
                            className="anim-row"
                        ></motion.div>
                        <div className="row">
                            <div className="cell">
                                <h6>TERRACE*</h6>
                            </div>
                            <div className="cell">
                                <h6>1,232</h6>
                            </div>
                            <div className="cell">
                                <h6>115</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <h6>FOURTH</h6>
                            </div>
                            <div className="cell">
                                <h6>3,315</h6>
                            </div>
                            <div className="cell">
                                <h6>308</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <h6>THIRD</h6>
                            </div>
                            <div className="cell">
                                <h6>4,820</h6>
                            </div>
                            <div className="cell">
                                <h6>448</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <h6>Second</h6>
                            </div>
                            <div className="cell">
                                <h6>4,973</h6>
                            </div>
                            <div className="cell">
                                <h6>462</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <h6>FIRST</h6>
                            </div>
                            <div className="cell">
                                <h6>4,884</h6>
                            </div>
                            <div className="cell">
                                <h6>450</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <h6>GROUND FLOOR</h6>
                            </div>
                            <div className="cell">
                                <h6>3,561</h6>
                            </div>
                            <div className="cell">
                                <h6>331</h6>
                            </div>
                        </div>
                        {/* <div className="row">
              <div className="cell">
                <h6>GROUND FLOOR LOBBY</h6>
              </div>
              <div className="cell">
                <h6>1,282</h6>
              </div>
              <div className="cell">
                <h6>119</h6>
              </div>
            </div> */}
                        <div className="row">
                            <div className="cell">
                                <h6>- RECEPTION*</h6>
                            </div>
                            <div className="cell">
                                <h6>1,302</h6>
                            </div>
                            <div className="cell">
                                <h6>121</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <h6>- OFFICES*</h6>
                                <p>(North and South)</p>
                            </div>
                            <div className="cell">
                                <h6>2,258</h6>
                            </div>
                            <div className="cell">
                                <h6>210</h6>
                            </div>
                        </div>
                        <motion.div
                            initial={{ height: 0 }}
                            whileInView={{ height: "22px" }}
                            style={{ backgroundColor: "#39805A" }}
                            transition={{ duration: 0.2, delay: 0.25 }}
                            className="anim-row"
                        ></motion.div>
                        <div className="row">
                            <div className="cell">
                                <h6>TOTALS**</h6>
                            </div>
                            <div className="cell">
                                <h6>21,481</h6>
                            </div>
                            <div className="cell">
                                <h6>1,996</h6>
                            </div>
                        </div>
                        <motion.div
                            initial={{ height: 0 }}
                            whileInView={{ height: "22px" }}
                            style={{ backgroundColor: "#D6CEBE" }}
                            transition={{ duration: 0.2, delay: 0.25 }}
                            className="anim-row"
                        ></motion.div>
                        <p className="legal">*NOT INCLUDED IN NIA</p>
                        <p className="legal">**EXCLUDED TERRACE DIM</p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="avail-three contain">
                    <div className="btns-wrapper">
                        <div className="f-s">
                            <h6
                                className={`${!isSpacePlan && "active"}`}
                                onClick={() => setIsSpacePlan(!isSpacePlan)}
                            >
                                FLOOR PLANS
                            </h6>
                            <h6
                                className={`${isSpacePlan && "active"}`}
                                onClick={() => setIsSpacePlan(!isSpacePlan)}
                            >
                                SPACE PLANS
                            </h6>
                        </div>
                        <div className="floor-btns">
                            <p className="small">CURRENTLY VIEWING</p>
                            <div className="btn-wrapper">
                                <h6
                                    className={`${
                                        floorplan === 0 ? "active" : ""
                                    } circle`}
                                    onClick={() => setFloorplan(0)}
                                >
                                    LG
                                </h6>
                                <h6
                                    className={`${
                                        floorplan === 1 ? "active" : ""
                                    } circle`}
                                    onClick={() => setFloorplan(1)}
                                >
                                    G
                                </h6>
                                <h6
                                    className={`${
                                        floorplan === 2 ? "active" : ""
                                    } circle`}
                                    onClick={() => setFloorplan(2)}
                                >
                                    1
                                </h6>
                                <h6
                                    className={`${
                                        floorplan === 3 ? "active" : ""
                                    } circle`}
                                    onClick={() => setFloorplan(3)}
                                >
                                    2
                                </h6>
                                <h6
                                    className={`${
                                        floorplan === 4 ? "active" : ""
                                    } circle`}
                                    onClick={() => setFloorplan(4)}
                                >
                                    3
                                </h6>
                                <h6
                                    className={`${
                                        floorplan === 5 ? "active" : ""
                                    } circle`}
                                    onClick={() => setFloorplan(5)}
                                >
                                    4
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="text">
                            <AnimatePresence>
                                {floorplan === 0 && (
                                    <motion.div
                                        variants={variantsR}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="floorplan-text"
                                    >
                                        <h5>LOWER GROUND FLOOR</h5>
                                        <h5>2,247 SQ FT</h5>
                                        <p className="large">209 SQ M</p>
                                    </motion.div>
                                )}
                                {floorplan === 1 && (
                                    <motion.div
                                        variants={variantsR}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="floorplan-text"
                                    >
                                        <h5>GROUND FLOOR</h5>
                                        <h5>3,561 SQ FT</h5>
                                        <p className="large">331 SQ M</p>
                                    </motion.div>
                                )}
                                {floorplan === 2 && (
                                    <motion.div
                                        variants={variantsR}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="floorplan-text"
                                    >
                                        <h5>FIRST FLOOR</h5>
                                        <h5>4,884 SQ FT</h5>
                                        <p className="large">450 SQ M</p>
                                    </motion.div>
                                )}
                                {floorplan === 3 && (
                                    <motion.div
                                        variants={variantsR}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="floorplan-text"
                                    >
                                        <h5>SECOND FLOOR</h5>
                                        <h5>4,973 SQ FT</h5>
                                        <p className="large">462 SQ M</p>
                                    </motion.div>
                                )}
                                {floorplan === 4 && (
                                    <motion.div
                                        variants={variantsR}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="floorplan-text"
                                    >
                                        <h5>THIRD FLOOR</h5>
                                        <h5>4,820 SQ FT</h5>
                                        <p className="large">448 SQ M</p>
                                    </motion.div>
                                )}
                                {floorplan === 5 && (
                                    <motion.div
                                        variants={variantsR}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="floorplan-text"
                                    >
                                        <h5>FOURTH FLOOR</h5>
                                        <h5>3,315 SQ FT</h5>
                                        <p className="large">308 SQ M</p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className="floorplan">
                            <AnimatePresence>
                                {floorplan === 0 && (
                                    <motion.img
                                        key="floorplan-0"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        src={`../images/availability/${
                                            isSpacePlan ? "space-" : ""
                                        }floorplan${floorplan}.svg`}
                                        alt="floorplan"
                                    />
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {floorplan === 1 && (
                                    <motion.img
                                        key="floorplan-0"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        src={`../images/availability/${
                                            isSpacePlan ? "space-" : ""
                                        }floorplan${floorplan}.svg`}
                                        alt="floorplan"
                                    />
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {floorplan === 2 && (
                                    <motion.img
                                        key="floorplan-0"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        src={`../images/availability/${
                                            isSpacePlan ? "space-" : ""
                                        }floorplan${floorplan}.svg`}
                                        alt="floorplan"
                                    />
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {floorplan === 3 && (
                                    <motion.img
                                        key="floorplan-0"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        src={`../images/availability/${
                                            isSpacePlan ? "space-" : ""
                                        }floorplan${floorplan}.svg`}
                                        alt="floorplan"
                                    />
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {floorplan === 4 && (
                                    <motion.img
                                        key="floorplan-0"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        src={`../images/availability/${
                                            isSpacePlan ? "space-" : ""
                                        }floorplan${floorplan}.svg`}
                                        alt="floorplan"
                                    />
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {floorplan === 5 && (
                                    <motion.img
                                        key="floorplan-0"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        src={`../images/availability/${
                                            isSpacePlan ? "space-" : ""
                                        }floorplan${floorplan}.svg`}
                                        alt="floorplan"
                                    />
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className="north-footer">
                        <img
                            src="../images/availability/north.svg"
                            alt="north"
                        />
                        <div>
                            <p>
                                Plans not to scale.
                                <br />
                                For indicative purposes only.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="avail-four contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            Find the
                            <br />
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                correct fit
                            </motion.span>
                        </motion.h2>
                    </div>
                    {/* <p>
            Sed non purus id tellus pharetra dignissim. Sed eu dolor maximus
            condimentum nulla id, consectetur odio. Praesent risus tellus
            venenatis quis maximus in faucibus ultricies enim. Vestibulum nec
            consequat ante. Mauris ut turpis imperdiet.
          </p> */}
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="avail-five contain">
                    <img
                        src="../images/building/gallery/4.jpg"
                        alt="floorplan"
                    />
                    <br />
                    <br />
                    <p>Second Floor Workspace CAT A</p>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="card-wrapper contain">
                    <Card
                        title={"PROTECT THE PLANET & YOUR BOTTOM LINE"}
                        highlight={5}
                        description={"sustainability"}
                        accentColor={"#2A2F1D"}
                        arrowColor={"#2A2F1D"}
                        backgroundColor={"#CC8B86"}
                        copyColor={"#D9CFBD"}
                        link={"sustainability"}
                        number={"03"}
                    />
                    <Card
                        title={"PERFECT YOUR WORK-LIFE SYMMETRY"}
                        highlight={3}
                        description={"LOCATION"}
                        accentColor={"#CC8B86"}
                        arrowColor={"#2A2F1D"}
                        backgroundColor={"#CC8B86"}
                        copyColor={"#D9CFBD"}
                        link={"LOCATION"}
                        number={"04"}
                        isImage={true}
                        imageUrl={"../images/card-image.jpg"}
                    />
                </div>
            </div>
        </>
    );
};
export default Availability;
