import { motion } from "framer-motion";
import { getHighlightAnimation } from "../../utils/animations/globalAnims";
import "./building.scss";
import Card from "../../components/cards/card";
import AnimatedGalleryBuilding from "../../components/gallery/animated/animatedGalleryBuilding";
import { useLayoutEffect } from "react";

const Building = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <>
            <div className="contain-wrapper">
                <div className="build-one contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            WHERE IDEAS COME TO{" "}
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                FRUITION
                            </motion.span>
                        </motion.h2>
                    </div>
                    <div className="copy-wrapper">
                        <h5>THE BUILDING</h5>
                        <p>
                            Workspaces should do more than merely function,
                            providing clearly defined spaces that meet the
                            exacting needs of modern business. Thoughtfully
                            curated decor should stimulate creativity and reduce
                            stress, creating productive spaces where ideas
                            become a reality.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-two contain">
                    <AnimatedGalleryBuilding />
                </div>
            </div>
            <div className="contain-wrapper olive-back">
                <div className="build-three contain">
                    <h5>DO EVERYTHING WELL</h5>
                    <div className="c-b">
                        <div className="c">
                            <motion.div
                                initial={{ height: 0 }}
                                whileInView={{ height: "32px" }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                                className="anim-block"
                            ></motion.div>
                            <h4>collaborative</h4>
                            <p className="reg">
                                Vibrant, co-working space integrated into
                                reception.
                            </p>
                        </div>
                        <div className="b">
                            <motion.div
                                style={{ backgroundColor: "#39805A" }}
                                initial={{ height: 0 }}
                                whileInView={{ height: "32px" }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                                className="anim-block"
                            ></motion.div>
                            <h4>bright</h4>
                            <p className="reg">
                                Big, beautiful, openable windows flood this
                                flexible space with natural light.
                            </p>
                        </div>
                    </div>
                    <div className="h-f-e">
                        <div className="h">
                            <motion.div
                                style={{ backgroundColor: "#23534E" }}
                                initial={{ height: 0 }}
                                whileInView={{ height: "32px" }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                                className="anim-block"
                            ></motion.div>
                            <h4>HQ</h4>
                            <p className="reg">
                                A spectacular self-contained HQ building
                                offering a remarkable workspace environment in
                                one of the most desirable parts of Camden.
                            </p>
                        </div>
                        <div className="f">
                            <motion.div
                                style={{ backgroundColor: "#9AAE9B" }}
                                initial={{ height: 0 }}
                                whileInView={{ height: "32px" }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                                className="anim-block"
                            ></motion.div>
                            <h4>Fresh</h4>
                            <p className="reg">
                                Exceptional top floor views and fully landscaped
                                terrace. Plus, fresh air to the building via two
                                air handling units.
                            </p>
                        </div>
                        <div className="e">
                            <motion.div
                                style={{ backgroundColor: "#CC8B86" }}
                                initial={{ height: 0 }}
                                whileInView={{ height: "32px" }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                                className="anim-block"
                            ></motion.div>
                            <h4>Exceptional</h4>
                            <p className="reg">
                                A striking Art Moderne façade.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-four contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            REACH&nbsp;
                            <br />
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                NEW HEIGHTS
                            </motion.span>
                        </motion.h2>
                    </div>
                    <p className="reg">
                        The terrace extends the working space, offering
                        comfortable seating and lush greenery. It serves those
                        simply looking for a break, fresh air or a dose of
                        vitamin D. Moreover, it doubles as functional meeting
                        spaces, perfect for brainstorming sessions and deep
                        dives. It’s a versatile space, and an indispensable
                        asset.
                    </p>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-five contain">
                    <div className="back-img"></div>
                    <br />
                    <p>Roof Terrace</p>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-six contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            Help in making&nbsp;
                            <br />
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                Healthy
                            </motion.span>
                            choices
                        </motion.h2>
                    </div>
                    <p className="reg">
                        These facilities champion a healthier lifestyle,
                        motivating physical activity. Whether you're freshening
                        up after a morning cycle commute, unwinding from a
                        post-lunch workout, or preparing to hit the road home,
                        our spacious shower facilities and well-appointed locker
                        rooms offer everything needed to keep you active and
                        revitalised.
                    </p>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-seven contain">
                    <div className="back-img"></div>
                    <p>Lower Ground Floor Cycle Suite</p>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-eight contain">
                    <div className="motion-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            <motion.span
                                {...getHighlightAnimation(0.2, "#CC8B86")}
                            >
                                High-quality
                            </motion.span>
                            <br />
                            materials and finishes
                        </motion.h2>
                    </div>
                    <p className="reg">
                        This project revitalises an Art Moderne building,
                        blending its historic exterior with a modern interior
                        design. The renovation combines restored brickwork,
                        metal, and limestone facades with an interior trio of
                        wood, metal, and concrete. Key features include polished
                        concrete and engineered oak timber flooring, fair-faced
                        blockwork walls, and coloured metal accents. The design
                        creates a harmony of industrial and warm materials,
                        resulting in a visually striking yet functional space
                        that respects the building's original aesthetic while
                        embracing contemporary elegance.
                    </p>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-nine contain">
                    <div className="collage">
                        <div className="main-img"></div>
                        <div className="other-wrapper">
                            <div className="top other-img"></div>
                            <div className="bottom other-img"></div>
                        </div>
                    </div>
                    {/* <p className="reg">Image Caption Text</p> */}
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="card-wrapper contain">
                    <Card
                        title={"WORK WITHOUT LIMITATIONS"}
                        highlight={3}
                        description={"SPACE"}
                        accentColor={"#CC8B86"}
                        arrowColor={"#DFD8CA"}
                        backgroundColor={"#DFD8CA"}
                        copyColor={"#2A2F1D"}
                        link={"availability"}
                        number={"02"}
                    />
                    <Card
                        title={"PROTECT THE PLANET & YOUR BOTTOM LINE"}
                        highlight={1}
                        description={"sustainability"}
                        accentColor={"#2A2F1D"}
                        arrowColor={"#2A2F1D"}
                        backgroundColor={"#CC8B86"}
                        copyColor={"#D9CFBD"}
                        link={"sustainability"}
                        number={"03"}
                    />
                </div>
            </div>
        </>
    );
};

export default Building;
