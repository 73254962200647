import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./styles.scss";

const images = [
    { src: "../images/building/gallery/1.jpg", caption: "101 Bayham" },
    { src: "../images/building/gallery/2.jpg", caption: "Entrance Lobby" },
    { src: "../images/building/gallery/3.jpg", caption: "Ground Floor Office Space" },
    { src: "../images/building/gallery/4.jpg", caption: "Second Floor Workspace CAT A" },
    { src: "../images/building/gallery/5.jpg", caption: "Second Floor Workspace CAT B" },
    { src: "../images/building/gallery/6.jpg", caption: "Fourth Floor Workspace CAT B" },
    { src: "../images/building/gallery/7.jpg", caption: "Roof Terrace" },
];

const Carousel: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        console.log(activeIndex);
    }, [activeIndex]);

    const handleDecrement = () => {
        if (activeIndex === 0) {
            setActiveIndex(images.length - 1);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    }

    const handleIncrement = () => {
        if (activeIndex === images.length - 1) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    }

    return (
        <>
            <div className="carousel">
                {images.map((image, index) => (
                    <motion.div
                        key={index}
                        initial={{
                            width:
                                activeIndex === index
                                    ? "80%"
                                    : activeIndex === 0 &&
                                      (index === 1 || index === 2)
                                    ? "10%"
                                    : activeIndex === index + 1 ||
                                      activeIndex === index - 1
                                    ? "10%"
                                    : "0%",
                        }}
                        animate={{
                            width:
                                activeIndex === index
                                    ? "80%"
                                    : activeIndex === 0 &&
                                      (index === 1 || index === 2)
                                    ? "10%"
                                    : activeIndex === index + 1 ||
                                      activeIndex === index - 1
                                    ? "10%"
                                    : "0%",
                        }}
                        transition={{ duration: 0.5 }}
                        className={`hero-image  ${(activeIndex === 0 && index === 2 ) && 'nextactive'}  ${activeIndex === index && 'active'}`}
                        style={{ backgroundImage: `url(${image.src})` }}
                    >
                        {activeIndex === index && (
                            <div className="caption">{image.caption}</div>
                        )}
                    </motion.div>
                ))}
            </div>
            <div className="nav-pag-wrapper">
                <div className="pagination-wrapper">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`pagination ${
                                activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => setActiveIndex(index)}
                        ></div>
                    ))}
                </div>
                <div className="btn-wrapper">
                    <div
                        className="prev"
                        onClick={() => handleDecrement()}
                    >
                        <img src="../images/arrow2.svg" alt="prev" />
                    </div>
                    <div
                        className="next"
                        onClick={() => handleIncrement()}
                    >
                        <img src="../images/arrow2.svg" alt="next" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Carousel;
