export const getHighlightAnimation = (
    delay: number,
    highlightColor = "yellow"
) => ({
    initial: { backgroundSize: "0% 100%" },
    whileInView: { backgroundSize: "100% 100%" },
    transition: { duration: 0.15, ease: "easeIn", delay: delay },
    style: {
        backgroundImage: `linear-gradient(to right, ${highlightColor}, ${highlightColor})`,
        backgroundRepeat: "no-repeat",
        padding: "0",        
        display: "inline-block",
    },
});