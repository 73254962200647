import { useEffect, useLayoutEffect } from "react";
import "../../scss/global.scss";
import "./home.scss";
import Card from "../../components/cards/card";
import { motion } from "framer-motion";
import AnimatedGallery from "../../components/gallery/animated/animatedGallery";
import { getHighlightAnimation } from "../../utils/animations/globalAnims";

const Home: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        function isSafari() {
            const ua = navigator.userAgent.toLowerCase();
            return ua.includes("safari") && !ua.includes("chrome");
        }

        if (isSafari()) {
            document.body.classList.add("is-safari");            
        }
    }, []);

    return (
        <motion.div exit={{ opacity: 0 }}>
            <div className="home-one">
                <video playsInline autoPlay muted>
                    <source src="../videos/intro.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="contain-wrapper">
                <div className="home-two contain">
                    <div className="copy-wrapper">
                        <motion.h2
                            initial={{ y: "100%" }}
                            whileInView={{ y: 0 }}
                            transition={{ duration: 0.5, type: "spring" }}
                        >
                            <motion.span
                                {...getHighlightAnimation(0.1, "#CC8B86")}
                            >
                                101
                            </motion.span>
                            &nbsp;BAYHAM <br />
                            STREET, CAMDEN
                        </motion.h2>
                    </div>
                    <div className="copy-wrapper">
                        <h5>AVAILABLE Q3 2025</h5>
                        <p className="large">
                            A reimagined 1930s Art Moderne factory, designed by
                            Henley Halebrown and Studio Ghazal to encourage
                            collaboration, allowing talent to feel energised and
                            inspired by their place of work.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="home-video contain" id="video">
                    <video
                        poster="../images/home/thumb.jpg"
                        controls
                        src="https://player.vimeo.com/progressive_redirect/playback/1018720996/rendition/1080p/file.mp4?loc=external&signature=dead8ce7ae0a1e75892efd1ddce7eb0fb752ef7c9f8a24212a628c5a2f4de92d"
                    ></video>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="home-five contain">
                    <AnimatedGallery />
                </div>
            </div>
            <div className="contain-wrapper green-back">
                <div className="home-three contain">
                    <div className="copy-wrapper">
                        <div className="h1-wrapper">
                            <motion.h1
                                initial={{ y: "100%" }}
                                whileInView={{ y: 0 }}
                                transition={{ duration: 0.5, type: "spring" }}
                            >
                                THIS IS <br />{" "}
                                <motion.span
                                    {...getHighlightAnimation(0.1, "#39805A")}
                                >
                                    WORK 101
                                </motion.span>
                            </motion.h1>
                        </div>
                        <p className="reg">
                            Places of work have the power to inspire and adapt
                            to meet the growing demands of 21st century
                            businesses. The Work 101 manifesto aims to redefine
                            workspace and transform it into a productive hub of
                            creativity and innovation.
                        </p>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper green-back">
                <div className="home-four contain">
                    <div className="card-container">
                        <Card
                            title={"A CREATIVE WORKSPACE THAT INSPIRES"}
                            highlight={5}
                            description={"BUILDING"}
                            accentColor={"#39805A"}
                            arrowColor={"#2A2F1D"}
                            backgroundColor={"#19423E"}
                            copyColor={"#D9CFBD"}
                            link={"building"}
                            number={"01"}
                        />
                        <Card
                            title={"WORK WITHOUT LIMITATIONS"}
                            highlight={3}
                            description={"SPACE"}
                            accentColor={"#39805A"}
                            arrowColor={"#2A2F1D"}
                            backgroundColor={"#19423E"}
                            copyColor={"#D9CFBD"}
                            link={"availability"}
                            number={"02"}
                        />
                    </div>
                    <div className="card-container">
                        <Card
                            title={"PROTECT THE PLANET & YOUR BOTTOM LINE"}
                            highlight={1}
                            description={"sustainability"}
                            accentColor={"#39805A"}
                            arrowColor={"#2A2F1D"}
                            backgroundColor={"#19423E"}
                            copyColor={"#D9CFBD"}
                            link={"sustainability"}
                            number={"03"}
                        />
                        <Card
                            title={"PERFECT YOUR WORK-LIFE SYMMETRY"}
                            highlight={3}
                            description={"LOCATION"}
                            accentColor={"#39805A"}
                            arrowColor={"#2A2F1D"}
                            backgroundColor={"#19423E"}
                            copyColor={"#D9CFBD"}
                            link={"location"}
                            number={"04"}
                        />
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="card-wrapper contain">
                    <Card
                        title={"A CREATIVE WORKSPACE THAT INSPIRES"}
                        highlight={5}
                        description={"BUILDING"}
                        accentColor={"#CC8B86"}
                        arrowColor={"#DFD8CA"}
                        backgroundColor={"#DFD8CA"}
                        copyColor={"#2A2F1D"}
                        link={"building"}
                        number={"01"}
                    />
                    <Card
                        title={"GET IN TOUCH TO FIND OUR MORE"}
                        highlight={3}
                        description={"CONTACT"}
                        accentColor={"#2A2F1D"}
                        arrowColor={"#2A2F1D"}
                        backgroundColor={"#CC8B86"}
                        copyColor={"#D9CFBD"}
                        link={"contact"}
                        number={"05"}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default Home;
