import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./iconSwiper.scss";

interface Icon {
  src: string;
  title: string;
  copy: string;
}

interface IconProps {
  icons: Icon[];
}

const IconSwiper: React.FC<IconProps> = ({ icons }) => {
  return (
    <SwiperComponent
      className="swiper-container"
      id="icon-swiper"
      slidesPerView={3}
      modules={[Autoplay, Navigation]}
      loop={true}
      pagination={{
        el: ".swiper-pagination",
        clickable: true,
      }}
      navigation={{
        nextEl: ".swiper-button-next-arrow",
        prevEl: ".swiper-button-prev-arrow",
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      }}
    >
      {icons.map((icon, index) => (
        <SwiperSlide key={index}>
          <img src={icon.src} alt="icon" />
          <p className="caps">{icon.title}</p>
          <p className="copy">{icon.copy}</p>
        </SwiperSlide>
      ))}
      <div className="swiper-pagination"></div>
      <div className="swiper-button-next-arrow"></div>
      <div className="swiper-button-prev-arrow"></div>
    </SwiperComponent>
  );
};

export default IconSwiper;
